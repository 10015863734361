import React, { useState, useCallback } from 'react';
import ReactFlow, { addEdge, useNodesState, useEdgesState, Controls, Background, Handle } from 'reactflow';
import 'reactflow/dist/style.css';

// Define a Custom Group Node Component
const GroupNode = ({ id, data }) => {
  const { label, children } = data;

  return (
    <div style={{ padding: 10, border: '1px solid black', borderRadius: 5, position: 'relative', width: 200, height: 200 }}>
      <div>{label}</div>
      <div style={{ position: 'relative', height: '100%', width: '100%' }}>
        {children.map((child) => (
          <div
            key={child.id}
            style={{
              position: 'absolute',
              top: child.position.y,
              left: child.position.x,
              border: '1px solid gray',
              padding: '5px',
              borderRadius: '3px',
              background: 'white'
            }}
          >
            {child.component}
          </div>
        ))}
      </div>
      <Handle type="source" position="right" />
      <Handle type="target" position="left" />
    </div>
  );
};

const nodeTypes = { group: GroupNode };

const initialNodes = [
  {
    id: 'group-1',
    type: 'group',
    position: { x: 100, y: 100 },
    data: {
      label: 'Group Node',
      children: []
    }
  }
];

const initialEdges = [];

const WfWithGroupNode = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const onDrop = (event) => {
    event.preventDefault();
    const reactFlowBounds = event.target.getBoundingClientRect();
    const type = event.dataTransfer.getData('application/reactflow');
    const position = {
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    };
    const newNode = {
      id: (nodes.length + 1).toString(),
      type,
      position,
      data: { label: `Node ${nodes.length + 1}` },
    };

    // Check if dropped inside a group node
    const groupNode = nodes.find((node) => node.type === 'group' && isInsideGroupNode(node, position));
    if (groupNode) {
      const newChild = { id: newNode.id, component: <div>{newNode.data.label}</div>, position };
      groupNode.data.children.push(newChild);
      setNodes([...nodes]);
    } else {
      setNodes((nds) => nds.concat(newNode));
    }
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const isInsideGroupNode = (groupNode, position) => {
    const { x, y } = position;
    const { position: groupPosition } = groupNode;
    return (
      x > groupPosition.x &&
      x < groupPosition.x + 200 && // assuming group node width
      y > groupPosition.y &&
      y < groupPosition.y + 200 // assuming group node height
    );
  };

  return (
    <div style={{ height: 800, display: 'flex' }}>
      <aside>
        <div
          className="dndnode"
          onDragStart={(event) => event.dataTransfer.setData('application/reactflow', 'default')}
          draggable
          style={{ margin: 10, padding: 10, border: '1px solid black', borderRadius: 5 }}
        >
          Drag Node
        </div>
      </aside>
      <div style={{ flexGrow: 1 }} onDrop={onDrop} onDragOver={onDragOver}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
        >
          <Background />
          <Controls />
        </ReactFlow>
      </div>
    </div>
  );
};

export default WfWithGroupNode;
