import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { WfWorkflow } from "../interfaces/Interfaces";
import Avatar from 'components/base/Avatar';
import { ProgressBar } from 'react-bootstrap';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import Badge from 'components/base/Badge';

export const workflowListTableColumns: ColumnDef<WfWorkflow>[] = [
  {
    accessorKey: 'name',
    header: 'Workflow Name',
    cell: ({ row: { original } }) => {
      const { name } = original;
      return (
        <Link to={`/crm-admin/workflow-details/${original.workflow_id}`} className="text-decoration-none fw-bold fs-8">
          {name}
        </Link>
      );
    },
    meta: {
      cellProps: { className: 'white-space-nowrap py-4' },
      headerProps: { style: { width: '30%' } }
    }
  },
  {
    accessorKey: 'description',
    header: 'Description',
    meta: {
      cellProps: { className: 'ps-3 text-900 py-4' },
      headerProps: { style: { width: '12%' }, className: 'ps-3' }
    }
  },
  {
    id: 'workflow_case_code',
    header: 'Case Code',
    meta: {
      cellProps: { className: 'ps-3 py-4' },
      headerProps: { style: { width: '5%' }, className: 'ps-3' }
    }
  },

  {
    header: 'Steps Designer',
    cell: ({ row: { original } }) => {
      const { name } = original;
      return (
        <Link to={`/crm-admin/workflow-designer/${original.workflow_id}`} className="text-decoration-none fw-bold fs-8">
          {name}
        </Link>
      );
    },
    meta: {
      cellProps: { className: 'white-space-nowrap py-4' },
      headerProps: { style: { width: '30%' } }
    }
  },
];

const WorkflowListTable = () => {
  return (
    <div className="border-bottom">
      <AdvanceTable
        tableProps={{ className: 'phoenix-table border-top border-200 fs-9' }}
      />
      <AdvanceTableFooter pagination className="py-3" />
    </div>
  );
};

export default WorkflowListTable;
