


const WorkflowSteps = () => {

    return (
        <div>
          <h1>WOrkflow steps</h1>
        </div>
          )

}

export default WorkflowSteps;